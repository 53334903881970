export function getThumbnail(item) {
  const thumbnailLocations = [
    item?.media_thumbnail?.medium?.src,
    item?.media_thumbnail?.medium?.thumbnail,
    item?.media?.[0]?.medium?.src,
    item?.media?.[0]?.medium?.thumbnail,
    item?.thumbnail,
    item?.featuredImage?.medium
      ? `https://fileserver.teachstarter.com${item.featuredImage.medium}`
      : null,
  ];
  return thumbnailLocations.filter(Boolean)[0] || '/images/default_thumbnail.png';
}

import { Resource, AlgoliaResource, ResourceContributor, ResourceTags } from '~/types/resource';
import { convertToApaTitleCase } from '~/utils/formatStrings';
import { getThumbnail } from '~/utils/thumbnail';

export type CardType = 'browse' | 'search' | 'blog';
export interface CardData {
  link: string;
  imgSrc: string;
  postType: string;
  postTypeTitle: string;
  excerpt: string;
  isFree: boolean;
  title: string;
  author: ResourceContributor;
  id: number;
  fileId?: number;
  modified?: string;
  episode?: number;
  isNew?: boolean;
  wordCount?: number;
  fileFormats: string[];
  yearLevels: string[];
  yearLevelsRange?: string;
  duration?: number;
  isTrending?: boolean;
  tags: Partial<ResourceTags>[];
}

type AlgoliaOrMongoResource = AlgoliaResource | Resource;

export const isAlgoliaResource = (
  resource: AlgoliaOrMongoResource,
  type: string
): resource is AlgoliaResource => {
  return type === 'search';
};

export const useCardData = (
  item: AlgoliaOrMongoResource,
  type: CardType,
  existingLink?: string
): CardData => {
  const link = ((): string => {
    if (existingLink) {
      return existingLink;
    }
    return isAlgoliaResource(item, type)
      ? item.url
      : item.link || `${item.country}/${item.type}/${item.slug}/`;
  })();

  const imgSrc = getThumbnail(item);
  const postType = isAlgoliaResource(item, type) ? item.postType : item.type;
  const postTypeTitle = postType ? convertToApaTitleCase(postType.split('-').join(' ')) : '';
  const excerpt = item.excerpt.replaceAll('\n', '').replaceAll('\r', '').replaceAll('\t', '');

  const isFree = isAlgoliaResource(item, type) ? !!item.isFree : item.is_free;
  const title = isAlgoliaResource(item, type) ? item.title : item.name;

  const author = item.author;
  const id = item.id;
  const fileId = isAlgoliaResource(item, type) ? undefined : item.file_id;
  const modified = isAlgoliaResource(item, type) ? undefined : item.modified;
  const episode = isAlgoliaResource(item, type) ? undefined : item.episode;
  const isNew = isAlgoliaResource(item, type) ? undefined : item.is_new;
  const wordCount = item.word_count;
  const fileFormats = isAlgoliaResource(item, type)
    ? item['file-format']?.lvl0 || []
    : item.files?.length > 0
      ? Array.from(new Set(item.files.map(file => file.format)))
      : [];
  const yearLevels = isAlgoliaResource(item, type)
    ? item['year-level-all'] || []
    : item.year_levels;
  const yearLevelsRange = isAlgoliaResource(item, type) ? item.yearLevels : item.year_levels_range;
  const duration = isAlgoliaResource(item, type) ? undefined : item.duration;
  const isTrending = isAlgoliaResource(item, type) ? undefined : item.is_trending;
  const tags = item.tags;

  return {
    link,
    imgSrc,
    postType,
    postTypeTitle,
    excerpt,
    isFree,
    title,
    author,
    id,
    fileId,
    modified,
    episode,
    isNew,
    wordCount,
    fileFormats,
    yearLevels,
    yearLevelsRange,
    duration,
    isTrending,
    tags,
  };
};

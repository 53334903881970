import { Ref, useStore, computed, useContext } from '@nuxtjs/composition-api';
import { User } from '~/types/user';
import { State } from '~/store';

interface UsePermissions {
  hasPermissions: Ref<boolean>;
}

export const usePermissions = (
  resourceType: string,
  isFreeResource: boolean,
  fileFormat?: string
): UsePermissions => {
  const store = useStore<State>();
  const loggedInUser = computed<User>(() => store.getters.loggedInUser);
  const { $config } = useContext();
  const { downloadableResourceTypes } = $config;
  const userHasTeachingResourcePermissions = computed<boolean>(() =>
    loggedInUser.value.capabilities.includes('access_product_teaching_resources')
  );
  const userHasEditableTeachingResourcePermissions = computed<boolean>(() =>
    loggedInUser.value.capabilities.includes('access_product_teaching_resources_editable')
  );
  const userHasResourcePackPermissions = computed<boolean>(() =>
    loggedInUser.value.capabilities.includes('access_product_teaching_resource_packs')
  );
  const userHasUnitLessPlanPermissions = computed<boolean>(() =>
    loggedInUser.value.capabilities.includes('access_product_units_lessons')
  );

  const hasPermissions = computed<boolean>(() => {
    if (!loggedInUser.value || !downloadableResourceTypes.includes(resourceType)) {
      return false;
    }

    if (
      resourceType === 'teaching-resource' &&
      (isFreeResource || userHasTeachingResourcePermissions.value)
    ) {
      return (
        fileFormat === 'pdf' ||
        fileFormat === 'png' ||
        userHasEditableTeachingResourcePermissions.value
      );
    }

    if (
      resourceType === 'resource-pack' &&
      (isFreeResource || userHasResourcePackPermissions.value)
    ) {
      return true;
    }

    if (
      (resourceType === 'unit-plan' || resourceType === 'lesson-plan') &&
      (isFreeResource || userHasUnitLessPlanPermissions.value)
    ) {
      return true;
    }
    return false;
  });
  return {
    hasPermissions,
  };
};

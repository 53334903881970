var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',{staticClass:"heading-3 !tw-mb-2 tw-whitespace-normal tw-text-tsblack",class:{
    'tw-line-clamp-1': _vm.lineClamp === 1,
    'tw-line-clamp-2': _vm.lineClamp === 2,
    'tw-line-clamp-3': _vm.lineClamp === 3,
    'tw-line-clamp-4': _vm.lineClamp === 4,
    'tw-line-clamp-5': _vm.lineClamp === 5,
  }},[_vm._v("\n  "+_vm._s(_vm.name)+"\n")])
}
var staticRenderFns = []

export { render, staticRenderFns }
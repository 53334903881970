var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{class:[
    _setup.borderClass(_vm.borderVariant || 'default'),
    _setup.bgClass(_vm.bgVariant || 'default'),
    'tw-border-grey-400 tw-inline-flex tw-h-6 tw-rounded tw-border !tw-px-2 !tw-py-1 tw-align-middle tw-text-xs tw-text-tsblack',
  ]},[(_vm.icon)?_c('svg',{class:[
      'tw-inline-block',
      _setup.iconClass(_vm.iconVariant || 'default'),
      { '!tw-mr-1.5': !_vm.ariaLabel },
    ],attrs:{"height":"14","width":"14","role":"img","aria-hidden":_vm.ariaLabel ? true : false,"aria-label":_vm.ariaLabel ?? undefined}},[_c('title',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('use',{attrs:{"xlink:href":_vm.icon}})]):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
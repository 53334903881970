import { ComputedRef, computed } from '@nuxtjs/composition-api';
import { useUser } from './useUser';
import { CardData } from './useCardData';

export type CardType = 'browse' | 'search' | 'blog';
export interface CardFunctions {
  isFree: ComputedRef<boolean>;
  showPriceTag: ComputedRef<boolean>;
}

export const useCardPrice = (item: CardData): CardFunctions => {
  const { user } = useUser();
  const isFree = computed(() => {
    if (
      !item.isFree ||
      item.postType === 'podcast' ||
      item.postType === 'blog' ||
      item.postType === 'widget' // && item.availability !== 'free') pretty confident we don't use this anymore so commenting out
    ) {
      return false;
    }
    return true;
  });
  const showPriceTag = computed(() => {
    if (item.postType === 'podcast' || item.postType === 'blog') {
      return false;
    }
    if (!user.value || user.value?.subscription?.code === 'free') {
      return true;
    }
    return false;
  });

  return {
    isFree,
    showPriceTag,
  };
};
